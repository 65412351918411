import React, { Fragment, useState } from 'react';
import I18n from 'i18n-js';
import ModularMetricsStatus from './ModularMetricsStatus';
import { defineAbilitiesFor } from '../../utils';
import QuitAlarmModal from '../device/elements/QuitAlarmModal';
import AlarmInfoModal from '../device/elements/AlarmInfoModal';
import axios from 'axios';
import moment from 'moment';

const DeviceDashboard = ({
  tenant_id,
  customer_id,
  site_id,
  user_id,
  device,
  user_locale,
  triggers,
  alarms_count,
  active_metrics_count,
  last_seen,
  locale,
  device_is_online,
}) => {
  const ability = defineAbilitiesFor(window.Abilities);

  const [trigger, setTrigger] = useState(0);
  const [trigger_filter, setTriggerFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRestart, setIsLoadingRestart] = useState(false);

  // Overview
  const Overview = () => {
    return (
      <Fragment>
        <div className="dashboard_overview_header">
          <span className="overview-title"> {I18n.t('shared.overview.overview')} </span>
        </div>
        <div className="group_tag_container card shadow" style={{ padding: '10px' }}>
          <table className="table table-bordered" style={{ marginTop: '15px' }}>
            <tbody>
              <tr>
                <td>{I18n.t('devices.dashboard.metrics_active')}</td>
                <td>{active_metrics_count} / 14</td>
              </tr>
              <tr>
                <td>{I18n.t('devices.dashboard.alarms_active')}</td>
                <td>
                  {alarms_count} / {triggers.length}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  };

  // Device metrics
  const DeviceCard = () => {
    return (
      <Fragment>
        <div className="dashboard_overview_header">
          <div className="row">
            <div className="col">
              <span className="overview-title lh-lg"> {device.name} </span>
            </div>
            <div className="col text-end">
              <span className="overview-title fs-5 lh-lg">
                {isOnline()}
                {' - '}
                {I18n.t('devices.dashboard.last_seen', {
                  last_seen: moment(last_seen).isValid()
                    ? moment(last_seen).locale(locale).fromNow()
                    : I18n.t('devices.dashboard.not_seen'),
                })}
              </span>
            </div>
          </div>
        </div>
        <div className="group_tag_container card shadow" style={{ padding: '10px 0 10px 10px' }}>
          <ModularMetricsStatus
            tenant_id={tenant_id}
            customer_id={customer_id}
            site_id={site_id}
            device_id={device.id}
            locale={user_locale}
          />
        </div>
      </Fragment>
    );
  };

  // Device functions
  const DeviceFunctions = () => {
    return (
      <Fragment>
        <div className="dashboard_overview_header" style={{ textAlign: 'center' }}>
          <span className="overview-title"> {I18n.t('devices.dashboard.functions')} </span>
        </div>
        <div className="group_tag_container card shadow" style={{ padding: '10px' }}>
          <button className="btn" onClick={() => refreshData()} disabled={isLoading}>
            {isLoading ? (
              <span>
                <span className="spinner-border spinner-border-sm"></span> Loading...
              </span>
            ) : (
              <span>
                <i className="fas fa-sync"></i> {I18n.t('devices.dashboard.refresh')}
              </span>
            )}
          </button>
          <button className="btn my-2" onClick={() => newAlarm()}>
            <i className="fas fa-plus-square" /> {I18n.t('devices.dashboard.new_alarm')}
          </button>
          <button className="btn" onClick={() => restartDevice()} disabled={isLoadingRestart}>
            {isLoadingRestart ? (
              <span>
                <span className="spinner-border spinner-border-sm"></span> Loading...
              </span>
            ) : (
              <span>
                <i className="fas fa-redo"></i> {I18n.t('devices.dashboard.restart_device')}
              </span>
            )}
          </button>
        </div>
      </Fragment>
    );
  };

  const refreshData = () => {
    setIsLoading(true);
    axios.get(Routes.refresh_data_api_v1_device_path(device.id)).then((response) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };

  const newAlarm = () => {
    window.location.replace(Routes.new_tenant_customer_trigger_path(tenant_id, customer_id, site_id, device.id));
  };

  function restartDevice() {
    setIsLoadingRestart(true);
    axios.get(Routes.restart_device_api_v1_device_path(device.id)).then((response) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  }

  function showMuteAlarm({ last_alarm }) {
    axios
      .get(Routes.show_mute_alarm_api_v1_tenant_path(tenant_id), {
        params: { alarm_id: last_alarm.id, user_id: user_id },
      })
      .then((response) => {
        window.open(response.data, '_blank').focus();
      });
  }

  // Alarms table
  const TriggersTable = (triggers) => {
    return (
      <Fragment>
        <div>
          <div className="dashboard_overview_header" style={{ textAlign: 'center' }}>
            <span className="overview-title"> {I18n.t('shared.breadcrumbs.controller_name.triggers')} </span>
          </div>
          <div className="row device-dashboard-chart">
            <div className="group_tag_container card shadow" style={{ padding: '10px' }}>
              <div className="d-flex" style={{ margin: 0 }}>
                <button
                  className="btn btn-small-custom btn-small-icon not-clickable index-search-button"
                  style={{ height: '32px', borderRadius: '3px 0 0 3px' }}
                >
                  <i className="fa fa-search" style={{ fontSize: '1em' }}></i>
                </button>
                <input
                  id="index-search"
                  autoFocus
                  className="form-control"
                  onChange={(e) => {
                    setTriggerFilter(e.target.value); // Set undefined to remove the filter entirely
                  }}
                  style={{
                    maxWidth: '250px',
                    height: '32px',
                    marginBottom: '0',
                    borderRadius: 0,
                    paddingLeft: '10px',
                  }}
                />
              </div>
              <table className="table table-striped table-bordered" style={{ marginTop: '10px' }}>
                <thead>
                  <tr className="row" style={{ padding: '0 10px', borderWidth: '0' }}>
                    <th className="col-6">{I18n.t('customers.index.name')}</th>
                    <th className="col-2">{I18n.t('customers.dashboard.condition')}</th>
                    <th className="col-2" style={{ textAlign: 'center' }}>
                      {I18n.t('customers.index.alarm_actions')}
                    </th>
                    <th className="col-2" style={{ textAlign: 'center' }}>
                      {I18n.t('customers.index.actions')}
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderTop: '0' }}>
                  {triggers.map((trigger) => {
                    return filterTriggers(trigger) || trigger_filter === null ? (
                      <tr key={trigger.id} className="row" style={{ padding: '0 10px', borderWidth: '0' }}>
                        <td className="col-6" style={{ backgroundColor: trigger.has_active_alarm ? 'orange' : '0' }}>
                          {trigger.last_alarm.is_muted && <i className="fas fa-volume-mute fs-6 me-1 text-black"></i>}{' '}
                          {trigger.name}
                        </td>
                        <td className="col-2" style={{ backgroundColor: trigger.has_active_alarm ? 'orange' : '0' }}>
                          {ShowTriggerCondition(trigger)}
                        </td>
                        <td className="col-2" style={{ backgroundColor: trigger.has_active_alarm ? 'orange' : '0' }}>
                          {trigger.has_active_alarm ? (
                            <span>
                              <button
                                className="btn btn-small"
                                style={{ marginRight: '5px', marginTop: '-2px' }}
                                type="button"
                                onClick={() => {
                                  showMuteAlarm(trigger);
                                }}
                              >
                                <i className="fas fa-volume-mute btn-small"></i>
                              </button>
                              <button
                                className="btn btn-small"
                                style={{ marginRight: '5px', marginTop: '-2px' }}
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#showQuitAlarmModal"
                                onClick={() => {
                                  setTrigger(trigger);
                                }}
                              >
                                <i className="fas fa-comment btn-small"></i>
                              </button>
                              <button
                                className="btn btn-small"
                                style={{ marginRight: '5px', marginTop: '-2px' }}
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#showAlarmInfoModal"
                                onClick={() => {
                                  setTrigger(trigger);
                                }}
                              >
                                <i className="fas fa-info-circle btn-small"></i>
                              </button>
                            </span>
                          ) : null}
                        </td>
                        <td
                          className="col-2"
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            backgroundColor: trigger.has_active_alarm ? 'orange' : '0',
                          }}
                        >
                          <button
                            className="btn btn-small"
                            style={{ marginRight: '5px', marginTop: '-2px' }}
                            onClick={(e) => handleShowTrigger(trigger)}
                          >
                            <i className="fa fa-wrench btn-small"></i>
                          </button>
                          <button
                            className="btn btn-small btn-caution"
                            style={{ marginRight: '5px', marginTop: '-2px' }}
                            onClick={(e) => handleDeleteTrigger(trigger)}
                            disabled={!ability.can('destroy', 'Trigger')}
                          >
                            <i className="fa fa-trash btn-small"></i>
                          </button>
                        </td>
                      </tr>
                    ) : (
                      ''
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const ShowTriggerCondition = (trigger) => {
    switch (trigger.condition) {
      case 'greater':
        return I18n.t('triggers.general.condition_greater') + ' ' + trigger.threshold;
      case 'greater_equal':
        return I18n.t('triggers.general.greater_equal') + ' ' + trigger.threshold;
      case 'lower_equal':
        return I18n.t('triggers.general.lower_equal') + ' ' + trigger.threshold;
      case 'lower':
        return I18n.t('triggers.general.condition_lower') + ' ' + trigger.threshold;
      case 'delta_falling':
        return I18n.t('triggers.general.condition_delta_falling') + ' ' + trigger.threshold;
      case 'delta_rising':
        return I18n.t('triggers.general.condition_delta_rising') + ' ' + trigger.threshold;
      case 'on':
        return I18n.t('triggers.general.condition_on');
      case 'off':
        return I18n.t('triggers.general.condition_off');
      default:
        return '';
    }
  };

  const filterTriggers = (trigger) => {
    let flag = false;
    if (trigger.name.toLowerCase().includes(trigger_filter)) {
      flag = true;
    }
    return flag;
  };

  const handleShowTrigger = (trigger) => {
    window.location.replace(
      Routes.general_tenant_customer_trigger_path(
        tenant_id,
        customer_id,
        trigger.site_id,
        trigger.device_id,
        trigger.id
      )
    );
  };
  const isOnline = () => {
    switch (device_is_online) {
      case true:
        return <span className="text-capitalize">{I18n.t('devices.dashboard.online_status')}</span>;
      case false:
        return <span className="text-capitalize">{I18n.t('devices.dashboard.offline_status')}</span>;
      default:
        return <span className="text-capitalize">{I18n.t('devices.dashboard.error_status')}</span>;
    }
  };
  const handleDeleteTrigger = (trigger) => {
    Swal.fire({
      title: I18n.t('triggers.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d90d0d',
      confirmButtonText: I18n.t('triggers.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('triggers.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_trigger_path(
            tenant_id,
            customer_id,
            trigger.site_id,
            trigger.device_id,
            trigger.id
          ),
          type: 'DELETE',
        });
      }
    });
  };

  // Return
  return (
    <Fragment>
      <QuitAlarmModal triggerId={trigger ? trigger.id : 0} userId={user_id} />
      <AlarmInfoModal alarm={trigger.last_alarm ? trigger.last_alarm : 0} />
      {Overview()}
      <div className="row">
        <div className="col-9">{DeviceCard()}</div>
        <div className="col-3">{DeviceFunctions()}</div>
      </div>
      <div className="row">{TriggersTable(triggers)}</div>
    </Fragment>
  );
};

export default DeviceDashboard;
